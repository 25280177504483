import React, { ReactElement } from "react"
import Seo from "../components/seo"
import DefaultLayout from "../templates/DefaultLayout"

interface Props {}

export default function Page404({}: Props): ReactElement {
  return (
    <DefaultLayout>
      <Seo title="404" description="" />
      <div className="min-h-[80vh] flex items-center justify-center flex-col">
        <h1 className="text-5xl lg:text-9xl text-br-primary-blue font-bold text-center">
          404
        </h1>
        <h1 className="text-3xl lg:text-7xl text-br-primary-blue font-medium text-center">
          Page Not Found
        </h1>
      </div>
    </DefaultLayout>
  )
}
